<template>
	<div class="border rounded-sm pl-2 pr-2 pb-2">
		<div class="text-center"><i class="ri-information-line" style="font-size: 48px; line-height: 1.2" /></div>
		<h4 class="text-center">{{ title }}</h4>
		<p style="font-size: 16px;" v-html="getLocaleText(message, 'message')"></p>
        <hr>
        <div class="row">
            <div class="col-12 text-center">
                <b-check v-model="approval" size="md">{{$t('i_have_read_understood_and_approve')}}</b-check>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2" v-if="approval">
                <button class="btn btn-success" @click="approve">{{$t('approve')}}</button>
            </div>
        </div>
	</div>
</template>

<script>
import InformationMessageService from "@/services/InformationMessageService";
	export default {
		props: {
			title: {
				type: String,
				default: null,
			},
			message: {
				type: Object,
				default: null,
			},
            semester_id: {
                type: Number,
                default: null,
            },
		},
        data(){
            return {
                approval:false
            }
        },
        methods: {
            approve(){
                let formData = {
                    information_message_id: this.message.id,
                    semester_id: this.semester_id
                }
                InformationMessageService.read(formData).then(response=>{
                    this.$emit('approved');
                }).catch(e=>{
                    this.showErrors(e);
                });
            }
        }
	};
</script>
