<template>
    <div>
        <div v-html="constantText">

        </div>
    </div>
</template>
<script>

import ConstantService from "@/services/ConstantService"
import qs from "qs";

export default {
    name: "CourseRegistrationInformation",
    props: {
        student: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            documentLink: null,
            constantText: null
        };
    },

    methods: {
        getConstants(){
            let constItemIdFor = this.constantSwitch(this.student.level)
            let key = 'constant_'+constItemIdFor.html;
            let local = this.getWithExpiry(key);
            if(local){
                this.constantText = local;
                return;
            }
            const config = {
                params: {
                    'key': constItemIdFor.html
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            ConstantService.getValue(config).then(response => {
                this.constantText = response.data.data
                this.setWithExpiry(key, response.data.data);
            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        constantSwitch(level){
            ///Lisansüstü
            if(['YL','D'].includes(level)){
                if(this.$i18n.locale == 'en') {
                    return {html: 'course_registration.graduate_information_text_en', file: 237}
                } else {
                    return {html: 'course_registration.graduate_information_text_tr', file: 235}
                }
            }
            ///Önlisnas-Lisans
            else {
                if(this.$i18n.locale == 'en') {
                    return {html: 'course_registration.undergraduate_and_associate_degree_information_text_en', file: 233}
                } else {
                    return {html: 'course_registration.undergraduate_and_associate_degree_information_text_tr', file: 231}
                }
            }
        }
    },

    created() {
        this.getConstants()
    },
};
</script>
