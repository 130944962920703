import Api from '@/services/Index';

const get = async (id) => {
    return Api.get('/information/messages/'+id);
}

const read = async (data) => {
    return Api.post('/information/message/reads', data);
}

export default {
    get,
    read
}
